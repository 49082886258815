import BreadCrumb from "../components/BreadCrumb";
import Container from "../components/Container";
import bgImage from "../utils/images/Board-of-Directors-2.jpg";
import mdImage from "../utils/images/Divya-Singh-237x300.png";
import edImage from "../utils/images/Lalit-Singh.jpg";

const BoardOfDirectors: React.FC = () => {
  return (
    <>
      <div
        className="bg-fixed bg-cover bg-top-4 bg-no-repeat md:block hidden"
        style={{ backgroundImage: `url(${bgImage})`, height: "30rem" }}
      ></div>
      <BreadCrumb pageName="Board of Directors" />
      <Container>
        <div className="flex items-center justify-center flex-col gap-y-5 mb-5">
          <h1 className="md:text-5xl text-2xl font-semibold mt-5">
            Board of Directors
          </h1>
          <p className="text-md font-medium text-center text-gray-600 px-8 lg:px-56">
            Golden Legand's Seasoned Management Team has decades of experience
            and key industry relationships that enable us to offer additional
            flexibility in terms of specific client requirements for growth
            purposes.
          </p>
          <div className="flex md:flex-row flex-col gap-6 mt-4">
            <div className="border rounded-md shadow-xl lg:border-none lg:rounded-none lg:shadow-none px-16 py-8 hover:shadow-xl hover:-translate-y-1 transition-transform">
              <img
                src={mdImage}
                alt="mdImage"
                width={237}
                className="rounded-image h-72"
                loading="lazy"
              />
              <h4 className="text-center text-xl font-semibold mt-4 flex flex-col">
                Divya Singh
                <span className="text-center font-normal text-sm mt-1">
                  Managing Director
                </span>
              </h4>
            </div>
            <div className="border rounded-md shadow-xl lg:border-none lg:rounded-none lg:shadow-none px-16 py-8 hover:shadow-xl hover:-translate-y-1 transition-transform">
              <img
                src={edImage}
                alt="edImage"
                width={237}
                className="rounded-image h-72"
                loading="lazy"
              />
              <h4 className="text-center text-xl font-semibold mt-4 flex flex-col">
                Lalit Singh
                <span className="text-center font-normal text-sm mt-1">
                  Executive Director
                </span>
              </h4>
            </div>
          </div>
        </div>
      </Container>
      <div className="bg-gray-50 py-4">
        <Container>
          <h1 className="uppercase text-primary font-semibold text-lg text-center">
            Listing
          </h1>
          <div className="flex md:flex-row flex-col justify-center mt-2 lg:px-[6rem] xl:px-[14rem]  gap-y-12 lg:gap-y-0">
            <div className="w-full lg:w-1/2 px-8 direction-rtl flex lg:items-center flex-col justift-center lg:block">
              <h3 className="text-xl relative pb-1 w-fit after:content-[''] after:bg-primary after:w-full after:h-[3px] after:absolute after:bottom-0 after:left-0 after:right-0">
                Company Secretary
              </h3>
              <h3 className="text-md font-semibold text-gray-700 mt-8">
                Prisha Jitendra Behel
              </h3>
              <h4 className="text-md font-normal text-gray-500 mt-4">
                202, Shri Ramakrishna Chambers
                <br /> Plot No. 67-B, Tps – Iv, Linking Road,
                <br />
                Khar(W) Mumbai 400052
              </h4>
              <h4 className="text-md font-semibold text-gray-700 mt-4">
                Phone No.:{" "}
                <span className="text-md font-normal text-gray-500">
                  022-68400400
                </span>
              </h4>
              <h4 className="text-md font-semibold text-gray-700 mt-4">
                Email ID:{" "}
                <a
                  href="mailto:cs@gllfl.com.test"
                  className="text-md font-normal text-gray-500"
                >
                  cs@gllfl.com
                </a>
              </h4>
              <h4 className="text-md font-semibold text-gray-700">
                Website:{" "}
                <a href="/" className="text-md font-normal text-gray-500">
                  www.gllfl.com
                </a>
              </h4>
            </div>
            <div className="w-full lg:w-1/2 px-8 direction-ltr flex lg:items-center flex-col justift-center lg:block">
              <h3 className="text-xl relative pb-1 w-fit after:content-[''] after:bg-primary after:w-full after:h-[3px] after:absolute after:bottom-0 after:left-0 after:right-0">
                Registrar and Share Transfer Agent
              </h3>
              <h3 className="text-md font-semibold text-gray-700 mt-8">
                Satellite Corporate Services Pvt. Ltd
              </h3>
              <h4 className="text-md font-semibold text-gray-700 mt-4">
                GST No.:{" "}
                <span className="text-md font-normal text-gray-500">
                  27AAACG1540L1ZQ
                </span>
              </h4>
              <h4 className="text-md font-semibold text-gray-700">
                CIN No.:{" "}
                <span className="text-md font-normal text-gray-500">
                  L65990MH1984PLC033818
                </span>
              </h4>
              <h4 className="text-md font-semibold text-gray-700">
                LEI No.:{" "}
                <span className="text-md font-normal text-gray-500">
                  984500B851F5BNA97906
                </span>
              </h4>
              <h4 className="text-md font-semibold text-gray-700">
                State Name:{" "}
                <span className="text-md font-normal text-gray-500">
                  Maharashtra, Code: 27
                </span>
              </h4>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default BoardOfDirectors;
