import BreadCrumb from "../components/BreadCrumb";
import companyLogo from "../utils/images/cropped-GLLFL-Logo.png";
import "../utils/styles/payEmi.css";
import PersonalImg from "../utils/images/PayEmi/l2.png";
import VehicleImg from "../utils/images/PayEmi/l3.png";
import PropertyImg from "../utils/images/PayEmi/l4.png";
import SharesImg from "../utils/images/PayEmi/l5.png";
import HomeImg from "../utils/images/PayEmi/l6.png";
import BusinessImg from "../utils/images/PayEmi/l7.png";
import WeddingImg from "../utils/images/PayEmi/l8.png";
import DoctorImg from "../utils/images/PayEmi/l9.png";
import TravelImg from "../utils/images/PayEmi/l10.png";
import HolidayImg from "../utils/images/PayEmi/l11.png";
import CAImg from "../utils/images/PayEmi/l12.png";
import VisaCard from "../utils/images/PayEmi/visacard.png";
import Phonepe from "../utils/images/PayEmi/phonepe.png";
import Paytm from "../utils/images/PayEmi/paytm.png";
import MasterCard from "../utils/images/PayEmi/mastercard.png";
import Gpay from "../utils/images/PayEmi/gpay.png";
import BhimUpi from "../utils/images/PayEmi/bhim-upi.png";
import Container from "../components/Container";
import { useState } from "react";

export default function PayEmi() {
  const [showformA, setShowformA] = useState(false);
  const [loanNumberInput, setLoanNumberInput] = useState("");
  const [laonNumberError, setLoanNumberError] = useState(false);
  const [showformD, setShowformD] = useState({
    debitCards: false,
    upi: false,
  });
  const [showformC, setShowformC] = useState(false);

  const [showformB, setShowformB] = useState(false);
  const [lacName, setLacName] = useState("");
  const [lacPhone, setLacPhone] = useState("");
  const [lacOTP, setLacOTP] = useState("");
  const [lacAmount, setLacAmount] = useState("");

  const [dataError, setDataError] = useState(false);
  const [dataSuccess, setDataSuccess] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowformA(true);
  };

  const handleChangeAccNo = (e) => {
    setLoanNumberInput(e.target.value);
  };

  const handleBlurAccNo = () => {
    if (loanNumberInput !== "123456") {
      setLoanNumberError(true);
    } else {
      setLoanNumberError(false);
    }
  };

  const loanAccount = () => {
    if (!laonNumberError) {
      setShowformB(true);
    }
  };

  // Validation Functions
  const validateLacName = () => {
    const regex = /^[A-Za-z\s]+$/;
    if (!regex.test(lacName)) {
      setDataError(true);
    } else {
      setDataError(false);
    }
  };

  const validateLacPhone = () => {
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(lacPhone)) {
      setDataError(true);
    } else {
      setDataError(false);
    }
  };

  const validateLacOTP = () => {
    const otpRegex = /^\d{4}$/;
    if (!otpRegex.test(lacOTP)) {
      setDataError(true);
    } else {
      setDataError(false);
    }
  };

  const validateLacAmount = () => {
    const amtRegex = /^\d+(\.\d{2})?$/;
    if (!amtRegex.test(lacAmount)) {
      setDataError(true);
    } else {
      setDataError(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!dataError) {
      setDataSuccess(true);
      setShowformC(true);
    } else {
      setDataSuccess(false);
      setShowformC(true);
    }
  };

  return (
    <div className="payEmi-wrapper">
      <BreadCrumb pageName="Pay Emi" />
      <div className="w-full h-full flex justify-center lg:justify-end items-center pt-0 p-10 md:pr-30 relative">
        <div className="py-8 px-0 md:px-6 w-full md:w-[650px] bg-white bg-opacity-90 min-h-[300px] rounded-lg shadow-lg backdrop-blur-xl backdrop-filter flex items-center justify-center flex-col">
          <Container>
            <div className="logo mb-4 flex justify-center">
              <img src={companyLogo} alt="logo" width={200} loading="lazy" />
            </div>
            <p className="text-slate-800 font-bold text-xs text-center">
              You are logged in from <span className="userIP" /> Mumbai,
              Maharashtra, India
            </p>
            <div className="mt-2 w-full">
              <h4 className="text-blue-700 text-md mb-6 font-bold capitalize text-center">
                Demo Payment Page
                <p className="text-slate-900 text-xs lg:text-md font-normal capitalize mt-2">
                  Select your loan
                </p>
              </h4>
              <div className="max-w-7xl mx-auto">
                <div className="flex items-center justify-center flex-wrap gap-x-4 gap-y-4 mb-6">
                  <button
                    className="text-center flex flex-col items-center group w-[60px] h-full"
                    onClick={handleClick}
                  >
                    <div className="bg-slate-100 border-1 border-blue-600 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1">
                      <img
                        src={PersonalImg}
                        alt="Personal Loan"
                        loading="lazy"
                      />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-600px" }}
                      />
                    </div>
                    <h5 className="text-xs font-medium">Personal</h5>
                  </button>

                  <button className="text-center flex flex-col items-center group w-[50px] h-full disabled-link">
                    <div className="bg-slate-100 border-1 border-blue-600 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1">
                      <img src={VehicleImg} alt="Vehicle Loan" loading="lazy" />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-100px" }}
                      />
                    </div>
                    <h5 className="text-xs font-medium">Vehicle</h5>
                  </button>

                  <button className="text-center flex flex-col items-center group w-[50px] h-full disabled-link">
                    <div className="bg-slate-100 border-1 border-blue-600 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1">
                      <img
                        src={PropertyImg}
                        alt="Property Loan"
                        loading="lazy"
                      />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-200px" }}
                      />
                    </div>
                    <h5 className="text-xs font-medium">Property</h5>
                  </button>

                  <button className="text-center flex flex-col items-center group w-[50px] h-full disabled-link">
                    <div className="bg-slate-100 border-1 border-blue-600 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1">
                      <img
                        src={SharesImg}
                        alt="Shares And Security Loan"
                        loading="lazy"
                      />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-300px" }}
                      >
                        {" "}
                      </div>
                    </div>
                    <h5 className="text-xs font-medium">Shares</h5>
                  </button>

                  <button className="text-center flex flex-col items-center group w-[50px] h-full disabled-link">
                    <div className="bg-slate-100 border-1 border-blue-600 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1">
                      <img src={HomeImg} alt="Home Loan" loading="lazy" />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-400px" }}
                      >
                        {" "}
                      </div>
                    </div>
                    <h5 className="text-xs font-medium">Home</h5>
                  </button>

                  <button className="text-center flex flex-col items-center group w-[50px] h-full disabled-link">
                    <div className="bg-slate-100 border-1 border-blue-600 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1">
                      <img
                        src={BusinessImg}
                        alt="Business Loan"
                        loading="lazy"
                      />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-500px" }}
                      >
                        {" "}
                      </div>
                    </div>
                    <h5 className="text-xs font-medium">Business</h5>
                  </button>

                  <button className="text-center flex flex-col items-center group w-[50px] h-full disabled-link">
                    <div className="bg-slate-100 border-1 border-blue-600 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1">
                      <img src={WeddingImg} alt="Wedding Loan" loading="lazy" />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-700px" }}
                      >
                        {" "}
                      </div>
                    </div>
                    <h5 className="text-xs font-medium">Wedding</h5>
                  </button>

                  <button className="text-center flex flex-col items-center group w-[50px] h-full disabled-link">
                    <div className="bg-slate-100 border-1 border-blue-600 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1">
                      <img src={DoctorImg} alt="Doctor Loan" loading="lazy" />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-800px" }}
                      >
                        {" "}
                      </div>
                    </div>
                    <h5 className="text-xs font-medium">Doctor</h5>
                  </button>

                  <button className="text-center flex flex-col items-center group w-[50px] h-full disabled-link">
                    <div className="bg-slate-100 border-1 border-blue-600 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1">
                      <img src={TravelImg} alt="Travel Loan" loading="lazy" />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-900px" }}
                      >
                        {" "}
                      </div>
                    </div>
                    <h5 className="text-xs font-medium">Travel</h5>
                  </button>

                  <button className="text-center flex flex-col items-center group w-[50px] h-full disabled-link">
                    <div className="bg-slate-100 border-1 border-blue-600 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1">
                      <img src={HolidayImg} alt="Holiday Loan" loading="lazy" />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-1000px" }}
                      >
                        {" "}
                      </div>
                    </div>
                    <h5 className="text-xs font-medium">Holiday</h5>
                  </button>

                  <button className="text-center flex flex-col items-center group w-[50px] h-full disabled-link">
                    <div className="bg-slate-100 border-1 border-blue-600 rounded-lg mb-2 md:w-[50px] md:h-[50px] flex items-center justify-center group-hover:shadow-lg duration-300 ease-in-out group-hover:bg-white p-1">
                      <img src={CAImg} alt="CA Loan" loading="lazy" />
                      <div
                        className="loans-sprite scale-75 md:scale-100"
                        style={{ backgroundPositionX: "-1100px" }}
                      >
                        {" "}
                      </div>
                    </div>
                    <h5 className="text-xs font-medium">CA</h5>
                  </button>
                </div>
              </div>
              {/* Personal loan form start */}
              <form className={`my-2 ${showformA ? "" : "hidden"} `}>
                <div>
                  <div
                    className="form_group w-full flex-col items-start md:flex-row flex md:items-center justify-between gap-y-3
                  "
                  >
                    <div className="underline-offset-2 underline font-medium">
                      Loan Account No.
                    </div>
                    <div className="flex flex-col w-full md:w-[40%]">
                      <input
                        className="w-full p-2 border rounded text-sm"
                        type="text"
                        placeholder="Loan Account No."
                        name="lac"
                        value={loanNumberInput}
                        onChange={(e) => {
                          handleChangeAccNo(e);
                        }}
                        onBlur={handleBlurAccNo}
                      />
                      <div
                        className={`${
                          laonNumberError ? "text-red-500" : "hidden"
                        } `}
                      >
                        Loan Account No. invalid
                      </div>
                    </div>
                    <button
                      type="button"
                      className="bg-blue-500 text-white p-2 rounded w-full md:w-36 hover:bg-blue-600 text-center text-sm"
                      onClick={loanAccount}
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <div className={`my-2 ${showformB ? "" : "hidden"} `}>
                  <div className={`${dataError ? "text-red-500" : "hidden"} `}>
                    Enter valid data
                  </div>
                  <div
                    className={`${dataSuccess ? "text-green-500" : "hidden"} `}
                  >
                    Successfully
                  </div>
                  <div className="form_group_grid w-full grid-cols-1 md:grid-cols-3 mt-2">
                    <div className="mb-2">
                      <input
                        className="w-full p-2 border rounded text-sm"
                        type="text"
                        placeholder="Name*"
                        name="lacName"
                        value={lacName}
                        onChange={(e) => setLacName(e.target.value)}
                        onBlur={validateLacName}
                        required
                      />
                    </div>
                    <div className="mb-2">
                      <input
                        className="w-full p-2 border rounded text-sm"
                        type="text"
                        placeholder="Phone No*"
                        name="lacPhone"
                        value={lacPhone}
                        onChange={(e) => {
                          setLacPhone(e.target.value);
                          if (!validateLacPhone(e.target.value)) {
                            setDataError(true);
                          } else {
                            setDataError(false);
                          }
                        }}
                        onBlur={() => {
                          if (!validateLacPhone(lacPhone)) {
                            setDataError(true);
                          } else {
                            setDataError(false);
                          }
                        }}
                      />
                    </div>
                    <div className="mb-2">
                      <input
                        className="w-full p-2 border rounded text-sm"
                        type="text"
                        placeholder="OTP*"
                        name="lacOTP"
                        value={lacOTP}
                        onChange={(e) => setLacOTP(e.target.value)}
                        onBlur={validateLacOTP}
                      />
                    </div>
                    <div>
                      <input
                        className="w-full p-2 border rounded text-sm"
                        type="text"
                        placeholder="Amount"
                        name="payAmount"
                        value={lacAmount}
                        onChange={(e) => setLacAmount(e.target.value)}
                        onBlur={validateLacAmount}
                      />
                      <div
                        className={`${lacAmount ? "text-red-500" : "hidden"} `}
                      />
                    </div>
                    <button
                      className="bg-blue-500 text-white p-2 rounded w-full md:w-36 hover:bg-blue-600 text-center text-sm"
                      onClick={handleSubmit}
                    >
                      Pay
                    </button>
                  </div>
                  <div className={`my-2 ${showformC ? "" : "hidden"} `}>
                    <div className="w-full">
                      <div className="mt-4 ml-2 underline-offset-2 underline font-medium text-sm">
                        Pay By
                      </div>
                      <div className="flex flex-row grow form_group">
                        <ul className="items-center w-full text-md font-medium text-gray-900 flex text-sm">
                          <li className="pr-2">
                            <div className="flex items-center">
                              <input
                                type="radio"
                                name="list-radio"
                                checked={showformD.debitCards}
                                onChange={() => {
                                  setShowformD({
                                    debitCards: true,
                                    upi: false,
                                  });
                                }}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 text-sm"
                              />
                              <label className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                Debit Cards{" "}
                              </label>
                            </div>
                          </li>
                          <li className="pr-2">
                            <div className="flex items-center">
                              <input
                                type="radio"
                                name="list-radio"
                                checked={showformD.upi}
                                onChange={() => {
                                  setShowformD({
                                    debitCards: false,
                                    upi: true,
                                  });
                                }}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 text-sm"
                              />
                              <label
                                htmlFor="UPI"
                                className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                UPI
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div>
                      <div
                        className={`my-2 ${
                          showformD.debitCards ? "" : "hidden"
                        }`}
                      >
                        <div className="text-sm text-blue-700 font-medium">
                          if Debit Card
                        </div>
                        <div className="flex flex-row grow">
                          <ul className="items-center w-full text-md font-medium text-gray-900 flex">
                            <li>
                              <div className="flex items-center md:pl-3">
                                <input
                                  type="radio"
                                  defaultValue="visaCard"
                                  name="debitCardList"
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 text-sm"
                                />
                                <label
                                  htmlFor="visaCard"
                                  className="w-full py-3 ml-2 text-md font-medium text-gray-900 dark:text-gray-300"
                                >
                                  <img
                                    src={VisaCard}
                                    className="w-[70px]"
                                    alt="visacard"
                                    loading="lazy"
                                  />
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="flex items-center pl-3">
                                <input
                                  type="radio"
                                  defaultValue="mastercard"
                                  name="debitCardList"
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 text-sm"
                                />
                                <label
                                  htmlFor="mastercard"
                                  className="w-full py-3 ml-2 text-md font-medium text-gray-900 dark:text-gray-300"
                                >
                                  <img
                                    src={MasterCard}
                                    className="w-[70px]"
                                    alt="mastercard"
                                    loading="lazy"
                                  />
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="flex flex-col md:flex-row form_group_grid gap-2 md:grid-cols-5">
                          <div className="col-span-2">
                            <input
                              className="w-full p-2 border rounded grow text-sm"
                              type="text"
                              placeholder="Name on Card"
                            />
                          </div>
                          <div className="col-span-2">
                            <input
                              className="w-full p-2 border rounded grow text-sm"
                              type="text"
                              placeholder="Card No."
                            />
                          </div>
                          <div>
                            <input
                              className="w-full p-2 border rounded grow-0 text-sm"
                              type="text"
                              placeholder="MM/YY"
                            />
                          </div>
                          <div>
                            <input
                              className="w-full p-2 border rounded grow text-sm"
                              type="text"
                              placeholder="CVV"
                            />
                          </div>
                          <div className="col-span-2">
                            <input
                              className="w-full p-2 border rounded grow text-sm"
                              type="text"
                              placeholder="Amount:"
                            />
                          </div>
                          <button
                            data-modal-target="pay-modal"
                            type="button"
                            className="bg-blue-500 text-white p-2 rounded w-full md:w-36 hover:bg-blue-600 text-center text-sm"
                            onClick={() => {
                              setShowModal(true);
                            }}
                          >
                            Pay
                          </button>
                        </div>
                        {/* Expiry and CVC */}
                        {/* Name on Card */}
                      </div>
                      <div className={`my-2 ${showformD.upi ? "" : "hidden"}`}>
                        <div className="text-sm text-blue-700 font-medium">
                          Select your UPI App
                        </div>
                        <div className="mt-6 mx-auto">
                          <div className="flex items-center justify-center flex-wrap flex-col gap-4 border border-gray-300 rounded-lg p-2 mb-8 UPI_section">
                            <div className="UPI_icons flex-col md:flex-row flex items-center gap-5">
                              <button className="bg-gray-100 hover:bg-slate-300 border rounded-lg border-gray-300 p-4 text-center mx-auto w-[100px] h-[50px] flex items-center justify-center">
                                <img
                                  src={BhimUpi}
                                  alt="bhimupi"
                                  loading="lazy"
                                />
                              </button>
                              <button className="bg-gray-100 hover:bg-slate-300 border rounded-lg border-gray-300 p-4 text-center mx-auto w-[100px] h-[50px] flex items-center justify-center">
                                <img
                                  src={Phonepe}
                                  alt="phonepe"
                                  loading="lazy"
                                />
                              </button>
                              <button className="bg-gray-100 hover:bg-slate-300 border rounded-lg border-gray-300 p-4 text-center mx-auto w-[100px] h-[50px] flex items-center justify-center">
                                <img src={Paytm} alt="paytm" loading="lazy" />
                              </button>
                              <button className="bg-gray-100 hover:bg-slate-300 border rounded-lg border-gray-300 p-4 text-center mx-auto w-[100px] h-[50px] flex items-center justify-center">
                                <img src={Gpay} alt="gapy" loading="lazy" />
                              </button>
                            </div>
                            <div className="UPI_btns">
                              <button className="bg-gray-100 border rounded-lg border-gray-30 p-4 text-center mx-auto col-span-2 text-blue-600 text-md font-medium hover:bg-primary hover:text-white">
                                OTHER UPI APPS
                              </button>
                            </div>
                            <div className="mx-auto col-span-2 w-full sm:w-1/2">
                              <div className="mb-3 flex relative w-full">
                                <input
                                  type="text"
                                  className="appearance-none w-full p-2 border rounded-tl-md rounded-bl-md grow text-sm"
                                  placeholder="Enter your Upi Id"
                                  aria-describedby="basic-addon2"
                                />
                                <span className="flex items-center text-base font-normal leading-normal text-gray-900 text-center whitespace-no-wrap bg-gray-300 rounded-tr-md rounded-br-md p-2">
                                  @upi
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          data-modal-target="verify-modal"
                          type="button"
                          className="mx-auto col-span-2 w-full text-center bg-blue-600 text-white p-4 verifyBtn hover:bg-primary"
                          onClick={() => {
                            setShowModal(true);
                          }}
                        >
                          Verify &amp; Pay
                        </button>
                      </div>
                      <div className="my-2 hidden">netBanking</div>
                      <div className="my-2 hidden">Wallet</div>
                    </div>
                  </div>
                </div>
              </form>
              {/* Personal loan form end */}
            </div>
          </Container>
        </div>
      </div>
      {/* pay popup modal html */}
      <div
        className={`bg-black/50 fixed top-0 left-0 w-full h-full z-10 flex items-center justify-center modal ${
          showModal ? "" : "hidden"
        }`}
      >
        <div className="relative p-4 pt-6 max-w-6xl py-12 px-12 w-[30%] bg-white bg-opacity-90 min-h-[400px] rounded-lg shadow-lg backdrop-blur-xl backdrop-filter flex items-center justify-center flex-col">
          <button
            className="absolute right-2 top-2 cursor-pointer"
            data-modal-hide="pay-modal"
            type="button"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-x"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          </button>
          <div className="logo mb-10">
            <img src={companyLogo} alt="logo" loading="lazy" />
          </div>
          <h4 className="font-bold text-2xl uppercase text-blue-600 mb-3">
            Thank you for Payment
          </h4>
        </div>
      </div>
    </div>
  );
}
