import img1 from "../../utils/images/Divya-Singh-150x150.png";
import img3 from "../../utils/images/TusharParekh-150x150.jpg";
import img4 from "../../utils/images/Prisha-150x150.jpg";
import img5 from "../../utils/images/Chetan-Photo-150x150.jpg";
import { ManagementDataItem } from "../../helpers/types";

export const managementData: ManagementDataItem[] = [
  {
    id: 1,
    img: img1,
    name: "Divya Singh Kushwaha",
    designation: "Managing Director",
    data: "Divya is the driving force behind GLLFL. With years of retail sales experience in the apparel segment of India, she brings a wealth of knowledge and expertise to the company. Divya comes from the erstwhile family of the diwan of Jhalawar, a once princely state in Rajasthan, and is passionate about animal welfare. In her free time, she takes care of sick animals and arranges for their medical treatments.",
  },
  {
    id: 2,
    img: img3,
    name: "Tushar Parekh",
    designation: "Chief Technical Officer (CTO)",
    data: "Tushar, an Engineer by profession, specializes in system and software architecture. With over 100 certifications and 27 years of experience in software technology, he leads a team of nearly 60 of the most advanced minds in the industry.",
  },
  {
    id: 4,
    img: img4,
    name: "Prisha Jitendra Behal",
    designation: "Company Secretary (CS)",
    data: "Prisha, a professionally qualified company secretary, ensures that GLLFL complies with financial and legal requirements and maintains high corporate governance standards.",
  },
  {
    id: 5,
    img: img5,
    name: "Chetan Nandwana",
    designation: "HR Head",
    data: "Chetan is working as a senior manager in HR and leads the HR department for GLLFL Group. He is a postgraduate and certified HR professional. He has more than 12 years of experience working as a human resources business partner. He plays a vital role in developing and implementing HR strategies and initiatives aligned with the overall business strategy. His impeccable communication skills nurture a positive work environment.",
  },
];
